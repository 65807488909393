const nav = document.querySelector("nav");
const navButton = document.querySelector(".btn-menu");
const navMenu = document.querySelector(".js-btn-menu");
const navLink = document.querySelectorAll(".nav-item");
let isNavOpen = false;

const highlightDotClassname = "highlight";

function toggleNav(e) {
  const method = isNavOpen ? "remove" : "add";
  const { currentTarget: element } = e;

  nav.classList[method]("active");
  element.classList.toggle("btn-menu-active");

  if (window.innerWidth < 700) {
    if (nav.classList.contains("active")) {
      navButton.setAttribute("aria-expanded", "true");
    } else {
      navButton.setAttribute("aria-expanded", "false");
    }
  }

  isNavOpen = !isNavOpen;
}

navButton.addEventListener("click", toggleNav);

/* Closes mobile menu on pressed link */
[...navLink].forEach((link) =>
  link.addEventListener("click", () => {
    nav.classList.remove("active");
    navMenu.classList.remove("btn-menu-active");
    isNavOpen = !isNavOpen;
  })
);

/* Close nav menu when resizing to landscape on mobile devices */
const debounce = (func, delay) => {
  let inDebounce;

  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

window.addEventListener(
  "resize",
  debounce(() => {
    if (isNavOpen && window.innerWidth >= 700) {
      nav.classList.remove("active");
      navMenu.classList.remove("btn-menu-active");
      isNavOpen = !isNavOpen;
    }
  }, 1),
  true
);
